/* src/components/styles.css */

/* General reset for margins and paddings */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
  }
  
  /* Container for the entire application */
  .container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Navbar styles */
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 10px 20px;
  }
  
  nav .logo {
    color: white;
    font-size: 24px;
    font-weight: bold;
  }
  
  nav ul {
    list-style: none;
    display: flex;
    align-items: center;
  }
  
  nav ul li {
    margin: 0 10px;
  }
  
  nav ul li a,
  nav ul li button {
    color: white;
    text-decoration: none;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  nav ul li button:hover,
  nav ul li a:hover {
    color: #ddd;
  }
  
  /* Form styles */
  form {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  form h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  form label {
    display: block;
    margin-bottom: 5px;
  }
  
  form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  form button {
    width: 100%;
    padding: 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  form button:hover {
    background-color: #555;
  }
  
  /* Home page and protected page styles */
  h2 {
    text-align: center;
    margin-top: 20px;
  }
  
  p {
    text-align: center;
    margin-top: 10px;
  }
  